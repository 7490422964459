/* eslint-disable react/no-array-index-key */
import React from 'react';
import Container from 'react-bootstrap/Container';
import ChargeListItem from './ChargeListItem';

function ChargeList({ charges, totalFine, totalJailTime, requiresCourt }) {
  return (
    <Container className="bg-white rounded-3 shadow-sm mb-3">
      {charges.map((charge, index) => (
        <ChargeListItem key={index} charge={charge} />
      ))}
      <br />
      <p>
        Fine: ${totalFine.toLocaleString()} <br />
        Sentence: {totalJailTime} months {requiresCourt ? ' + COURT' : ''}
      </p>
    </Container>
  );
}

export default ChargeList;
