/* eslint-disable prettier/prettier */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import './App.css';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { FormGroup } from 'react-bootstrap';
import ChargeList from './ChargeList';
// import { Stack } from 'react-bootstrap';
import penalCode from './penalCode.json';

function App() {
  const [charges, setCharges] = useState([]);
  const [totalFine, setTotalFine] = useState(0);
  const [totalJailTime, setTotalJailTime] = useState(0);
  const [requiresCourt, setRequiresCourt] = useState(false);
  const [filterStr, setFilterStr] = useState('');

  const handleChargeSelect = (charge) => {
    setCharges([...charges, charge]);
    setTotalFine(totalFine + charge.fine);
    if (charge.prisonTime === 'COURT') {
      setRequiresCourt(true);
    } else {
      setTotalJailTime(totalJailTime + Number(charge.prisonTime));
    }
  };

  const clearCharges = () => {
    setCharges([]);
    setTotalFine(0);
    setTotalJailTime(0);
    setRequiresCourt(false);
    setFilterStr('');
  };

  const cardColor = (charge) => {
    switch (charge.type) {
      case 'Misdemeanor':
        return 'text-warning';
      case 'Felony':
        return 'text-danger';
      case 'Infraction':
        return 'text-success';
      default:
        return '';
    }
  };
  const filteredPenalCode = penalCode.filter((e) => {
    if (filterStr) {
      return e.criminalOffense.toLowerCase().includes(filterStr.toLowerCase());
    }
    return true;
  });

  const handleSearchChange = (e) => {
    setFilterStr(e.target.value);
  };
  const handleFocus = (event) => event.target.select();

  return (
    <Container className="p-3">
      <Container className="p-5 mb-4 bg-light rounded-3">
        <ChargeList
          charges={charges}
          totalFine={totalFine}
          totalJailTime={totalJailTime}
          requiresCourt={requiresCourt}
        />
        <Form>
          <FormGroup className="mb-3">
            <Button
              variant="warning"
              onClick={clearCharges}
              name="clearCurrentCharges"
            >
              Clear Current Charges
            </Button>
          </FormGroup>
          <FormGroup className="mb-3">
            <Form.Control
              type="input"
              onChange={handleSearchChange}
              value={filterStr}
              onFocus={handleFocus}
              autoFocus
            />{' '}
          </FormGroup>
        </Form>
        <Row>
          {filteredPenalCode.map((charge, index) => (
            <Col key={index} className="py-2">
              <Card
                style={{ width: '23rem', height: '12rem' }}
                onClick={() => handleChargeSelect(charge)}
              >
                <Card.Body>
                  <Card.Title className={cardColor(charge)}>
                    {charge.criminalOffense}
                  </Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    {charge.prisonTime}{' '}
                    {charge.prisonTime !== 'COURT' ? 'months' : ''} -{' '}
                    {charge.fine.toLocaleString()}
                  </Card.Subtitle>
                  <Card.Text className="text-muted small">
                    <div className="overflow-auto">{charge.description}</div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
}

export default App;
