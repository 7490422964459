import React from 'react';

function ChargeListItem({ charge }) {
  return (
    <span>
      {charge.criminalOffense} | {charge.fine.toLocaleString()} |{' '}
      {charge.prisonTime} months <br />
    </span>
  );
}

export default ChargeListItem;
